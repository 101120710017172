import authApi from '../../utils/AxiosConfig'
import authHeader from '../../utils/authHeader'

// Stripe Subscription Status
export const startChat = async (data: object, token: string) => {
  const response = await authApi.post(
    '/buyer/chatbot',
    {
      ...data,
    },
    {
      headers: authHeader(),
      token,
    }
  )

  return response.data
}

const chatBotService = {
  startChat,
}

export default chatBotService
